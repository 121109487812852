$primary-color: #7DF9FF;
$secondary-color: #333;
$font-coolvetica: 'Coolvetica', sans-serif;
$font-aurore: 'La Belle Aurore', cursive;
$breakpoint-mobile: 480px;

// Mixin for common animations
@mixin animation-fadeIn($delay) {
    animation: fadeIn 1s $delay backwards;
}

.home-page {
    display: flex;
    justify-content: center; // Center horizontally
    align-items: center; // Center vertically
    height: 100vh; // Take full viewport height
    margin: 0;
    padding: 0;
    font-size: 36px; // Default for mobile
    margin-left: 30px;

    @media (min-width: $breakpoint-mobile) {
        font-size: 53px; // Larger screens
    }

    .text-zone {
        position: relative; // Changed from absolute for better Flexbox handling
        width: 80%; // Adjust width as necessary
        max-width: 600px;
        text-align: center; // Ensure text inside is centered

        @media (max-width: $breakpoint-mobile) {
            width: 90%; // Make it a bit wider on smaller screens if needed
        }
    }

    h1 {
        color: #fff;
        font-size: inherit; // Use font size from parent container
        margin: 0;
        font-family: $font-coolvetica;
        font-weight: 400;

        img {
            width: 32px;
            margin-left: 20px;
            opacity: 0;
            height: auto;
            animation: rotateIn 1s linear both;
            animation-delay: 1.4s;
        }
    }

    h2 {
        color: #8d8d8d;
        margin-top: 20px;
        font-weight: 400;
        font-size: 11px;
        font-family: sans-serif;
        letter-spacing: 2px;
        @include animation-fadeIn(1.8s);
    }

    .flat-button {
        color: $primary-color;
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 3px;
        font-family: sans-serif;
        text-decoration: none;
        padding: 10px 18px;
        border: 1px solid $primary-color;
        margin-top: 25px;
        white-space: nowrap;
        display: inline-flex; // Use inline-flex for better alignment
        justify-content: center; // Center horizontally
        align-items: center; // Center vertically
        position: relative; // Add position relative

        @include animation-fadeIn(1.8s);

        &:hover {
            background: $primary-color;
            color: $secondary-color;
        }

        @media (max-width: $breakpoint-mobile) {
            font-size: 16px; // Slightly larger for better readability and easier tapping
            padding: 12px 20px; // Larger padding for a bigger tap target
            margin-top: 20px; // Adjust margin as needed
            display: flex; // Block display to take the full width
            max-width: 70%; // Set a maximum width
            margin-left: auto; // Center the button horizontally
            margin-right: auto; // Center the button horizontally
            z-index: 10; // Ensure the button is on top

            @include animation-fadeIn(1.8s);
            justify-content: center; // Center horizontally
            align-items: center; // Center vertically
        }
    }
}