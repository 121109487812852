.contact-page {
  .text-zone {
    width: 90%; // Limits the width to 90% to ensure padding from the screen edge
    max-width: 800px; // Keeps the max-width for larger screens
    margin: 20px auto; // Adds margin on the top and bottom to ensure spacing around the text-zone
    padding: 20px; // Consistent padding
    box-shadow: 10px 15px rgba(0, 0, 0, 0.1); // Subtle shadow for depth
    margin-left: 10px;

    p {
      font-size: 22px; // Readable font size for the body text
      line-height: 1.6; // Line height for better readability
      margin-top: 0; // Removes top margin
      margin-bottom: 20px; // Space below the paragraph

    }

    @media (max-width: 768px) {
      width: calc(100% - 60px); // Increases the width to ensure padding from the navigation menu
      padding: 15px; // Adjusts padding for mobile
      box-shadow: 5px 7px rgba(0, 0, 0, 0.1); // Adjusts the shadow to be less intense
      margin: 0 auto; // Ensures centering on mobile
      display: block; // Change to block to avoid flex issues on mobile
      margin-left: 50px; // Adds padding to the left to avoid overlap with navigation

      h1 {
        margin-top: 90px;

        margin-left: 20px; // Adds padding to the left to avoid overlap with navigation


      }

      p {
        font-size: 18px; // Adjusts the font size to be smaller on mobile for better readability
        line-height: 1.4; // Slightly reduced line height for better mobile fit
        width: 90%;
      }
    }
  }
}

.contact-form {
  width: 80%;
  margin-top: 20px;

  ul {
    padding: 0;
    margin: 0;

    li {
      padding: 0;
      margin: 0;
      list-style: none;
      margin-bottom: 10px;
      opacity: 0;
      overflow: hidden;
      display: block;
      clear: both;
      position: relative;
      animation: fadeInUp 2s 2s;
      animation-fill-mode: forwards;
    }

    li.half {
      width: 49%;
      margin-left: 2%;
      float: left;
      clear: none;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  @media (max-width: 768px) {
    width: calc(100% - 60px); // Increases the width to ensure padding from the navigation menu
    padding: 15px; // Adjusts padding for mobile
    box-shadow: 5px 7px rgba(0, 0, 0, 0.1); // Adjusts the shadow to be less intense
    margin: 0 auto; // Ensures centering on mobile
    display: block; // Change to block to avoid flex issues on mobile
    margin-left: 0; // Adds padding to the left to avoid overlap with navigation
  }

  input[type='text'],
  input[type='email'] {
    width: 100%;
    border: 0;
    background: #115173;
    height: 50px;
    font-size: 16px;
    color: #fff;
    padding: 0 20px;
    box-sizing: border-box;
  }

  textarea {
    width: 100%;
    border: 0;
    background: #115173;
    height: 50px;
    font-size: 16px;
    color: #fff;
    padding: 20px;
    box-sizing: border-box;
    min-height: 150px;
  }

  .flat-button {
    color: #7DF9FF;
    font-size: 11px;
    letter-spacing: 3px;
    text-decoration: none;
    padding: 8px 10px;
    border: 1px solid #7DF9FF;
    float: left;
    border-radius: 4px;
    background: 0 0;
    text-transform: uppercase;
    float: right;
    text-align: center;
    margin-right: 10px;
  }
}

.map-wrap {
  background: rgba(8, 253, 216, 0.1);
  float: right;
  width: 53%;
  height: 115%;
  margin-top: -3%;
}

.leaflet-container {
  position: relative;
  width: 100%;
  height: 100%;
  opacity: 0;
  animation: backInRight 1s 1.2s;
  animation-fill-mode: forwards;
}

.info-map {
  position: absolute;
  background: #000;
  top: 50px;
  right: 30%;
  z-index: 999999;
  width: 267px;
  padding: 20px;
  color: #fff;
  font-family: 'Helvetica';
  font-size: 17px;
  font-weight: 300;
  opacity: 0;
  animation: fadeIn 1s 1.5s;
  animation-fill-mode: forwards;

  span {
    font-size: 16px;


    color: #7DF9FF;

  }
}

@media screen and (max-width: 1200px) {
  .map-wrap {
    float: none;
    margin: 0;
    width: 100%;
    height: 400px;
    margin-left: 60px;

  }

  .info-map {
    display: none;
  }
}